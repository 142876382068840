// MagicWay
const API = 'https://apimagicway.geniusws.com.br';


export const environment = {
  production: true,
  API: API,
  PAYPAL_CLIENT_ID: "",
  ambiente: "MAGIC",
  THEME: "", //(FRT - ARKBEDS - FRT-BLACK)
  //Módulos de turismo
  USA_CASAMENTO: false,
  USA_CONTRATO: true,
  USA_CIRCUITO: false,
  USA_TURISMO: true,
  USA_PACOTE: true,
  USA_SEGURO: false,
  USA_DISNEY: true,
  USA_CARRO: false,
  USA_AEREO: true,
  USA_LOJA: false,
  USA_CASA: false,
  USA_HOTEL: true,
  USA_BETO: true,
  USA_PROMOCIONAL: true,
  USA_MARKUPIMPRESSAO: false,
  USA_ARKBEDS: false,
  USA_TERMOMETRO: true,
  USA_SOLICITARDESCONTO: true,
  USA_CADASTROAGENCIA: true,
  //Apis dos módulos de turismo
  TURISMO_AEREO: API,
  TURISMO_HOTEL: API,
  TURISMO_SERVICO: API,
  TURISMO_SEGURO: API,
  TURISMO_CARRO: API,
  TURISMO: API,
  GERENCIAMENTO: API,
  EMPRESAS: API,

  //Módulos Adicionais
  USA_BLOQUEIO_FRT: false, // Permite o uso de um botão adicional na aba bloqueios
  USA_INTRANET: true, // Permite o uso da Intranet no portal
  USA_LAMINAS: true, // Permite Gerar Lâminas no portal
  USA_BANNERS: false, //Permite o uso de Banners

  //Configurações
  USA_PACOTESIMPLIFICADO: false,
  CLIENT_ID: "postman:123",
  MAPBOX_ACCESSTOKEN: "",
  ITENSPORPAGINA: 20,
  MAXIMO_QUARTOS: 8,
  TAG_GOOGLE: "",
  URLSITE: ''
};
