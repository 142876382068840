import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';

import 'rxjs/add/operator/do';

import { API } from '../app.config';
import { CLIENT_ID } from '../app.config';

var geolocalizacao: any = false;
@Injectable()
export class LoginService implements OnInit {

    private tempUser: any = null;
    private fingerprint: string;

    constructor(private http: HttpClient, private router: Router) {
        navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
    }

    ngOnInit() {
        navigator.geolocation.getCurrentPosition(this.getGeoLocalizacao);
    }

    today(): Date {
        return new Date();
    }

    user(): any {
        const usuario = JSON.parse(window.localStorage.getItem('loggedUser'));
        const agora = Date.now() / 1000;
        // const currentUrl = this.router.url;
        if (usuario === null) {
            this.handleLogin('');
            return null;
        } else if (usuario.portal && usuario.portal != window.location.pathname.split('/')[1]) {
            this.logout();
        }
        if (agora < usuario.token_expire) {
            return usuario;
        } else if (usuario != null) {
            this.logout();
        }
    }

    isLoggedIn() {
        return this.user() != null;
    }

    isLoggedInValidator(): boolean {
        let usuario = this.user();
        if (usuario != null) {
            const agora = Date.now() / 1000;
            console.log(usuario.token_expire);
            console.log(agora);
            console.log(agora < usuario.token_expire);
            return false;
        } else {
            return false;
        }
    }

    handleLogin(caminho: string) {
        // this.router.navigate(['/login', btoa(caminho)])
        //const currentUrl = this.router.url;
        let currentUrl = window.location.hash;
        if (!currentUrl.startsWith('#/contrato')) {
            let hash: string = window.location.hash;
            if (hash.includes("pt")) {
                this.router.navigate(['/auth/login/pt']);
            }
            else if (hash.includes("en")) {
                this.router.navigate(['/auth/login/en']);
            }
            else if (hash.includes("es")) {
                this.router.navigate(['/auth/login/es']);
            }
            else {
                this.router.navigate(['/auth/login/pt']);
            }
        }

    }

    getGeoLocalizacao(position) {
        geolocalizacao = position;
    }

    setTempUser(user: any) {
        this.tempUser = user;
    }

    getTempUser() {
        return this.tempUser;
    }

    clearTempUser() {
        this.tempUser = null;
    }

    clearFingerPrint() {
        this.fingerprint = null;
    }

    // logar(username: string, password: string): Observable<any> {
    logar(username: string, password: string) {
        let headers = new HttpHeaders().set('noloader', 't');
        headers = headers.append('Content-type', 'application/json');
        if (geolocalizacao) {
            headers = headers.append('latitude', geolocalizacao.coords.latitude);
            headers = headers.append('longitude', geolocalizacao.coords.longitude);
        }
        this.fingerprint = this.getFingerprint();
        headers = headers.append('fingerprint', this.fingerprint);

        const body = `grant_type=password&username=${username}&password=${password}&client_id=${CLIENT_ID}`;

        return this.http.post<any>(`${API}/token`, body, { headers: headers, observe: "response"})
            .do(response => {
                const dados = response.body;
                const data = dados.access_token.toString().split('.');
                const userTemp = JSON.parse(atob(data[1]));
                const usuario = JSON.parse(userTemp.usuario);

                usuario.access_token = dados.access_token;
                usuario.token_expire = (Date.now() / 1000) + dados.expires_in;

                //window.localStorage.setItem('loggedUser', JSON.stringify(usuario));
                this.setTempUser(usuario);

                if (response.status === 202){
                    this.router.navigate(["/auth/autenticacao"]);
                } else if (response.status === 200){
                    this.buscarDadosUsuario(usuario);
                    this.router.navigate(["/"]);
                }
                // this.buscarDadosUsuario(usuario);
                return usuario;
            });
    }

    public buscarDadosUsuario(usuario) {
        delete usuario.Nome;
        window.localStorage.setItem('loggedUser', JSON.stringify(usuario));
        this.getUserById(usuario.Id).subscribe(
            dados => {
                let user = dados.Items[0];
                delete user.Perfis;
                delete user.Roles;
                delete user.Alteracoes;
                delete user.Cidade.Alteracoes;
                // delete user.Agencia;

                if (window.location.host == 'ark.tur.br' && window.location.pathname.split('/')[1]) {
                    user.portal = window.location.pathname.split('/')[1];
                }

                user.access_token = usuario.access_token;
                user.token_expire = usuario.token_expire;
                window.localStorage.setItem('loggedUser', JSON.stringify(user));
            }, erro => {
                console.log("ERRO AO BUSCAR DADOS DO USUARIO");
                console.log(erro);
                this.buscarDadosUsuario(usuario);
            }
        );
    }

    logout() {
        // window.localStorage.removeItem('gy84d8');
        // window.localStorage.removeItem('a5s74');
        // window.localStorage.removeItem('loggedUser');
        window.localStorage.clear();
        window.sessionStorage.clear();
        // this.loggedUser = null;
        this.handleLogin('');
    }

    getUserById(id: number): Observable<any> {
        return this.http.get<any>(`${API}/api/usuario/${id}?Pagina=1&ItensPorPagina=1`);
    }

    recuperarSenha(obj: any): Observable<any> {
        return this.http.patch<any>(
            `${API}/api/usuario/recuperarSenha`,
            obj
        );
    }

    private collectBrowserData(): string {
        function getCanvasFingerprint() {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            ctx.textBaseline = 'top';
            ctx.font = '14px "Arial"';
            ctx.textBaseline = "alphabetic";
            ctx.fillStyle = "#f60";
            ctx.fillRect(125, 1, 62, 20);
            ctx.fillStyle = "#069";
            ctx.fillText("Browser Fingerprint", 2, 15);
            ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
            ctx.fillText("Browser Fingerprint", 4, 17);
            return canvas.toDataURL();
        }

        function getNavigatorInfo() {
            return [
                navigator.userAgent,
                navigator.language,
                navigator.platform,
                navigator.vendor,
                navigator.hardwareConcurrency || 'unknown',
                navigator.maxTouchPoints || 0,
                navigator.cookieEnabled ? 'Cookies Enabled' : 'Cookies Disabled',
                navigator.doNotTrack || 'unknown'
            ].join('tibia');
        }

        function getScreenInfo() {
            return screen.width + 'x' + screen.height + '|' + screen.colorDepth + '|' + screen.pixelDepth;
        }

        function getTimezoneOffset() {
            return new Date().getTimezoneOffset();
        }

        function getPlugins() {
            return Array.from(navigator.plugins).map(plugin => plugin.name).join(',');
        }

        const fingerprintData = [
            getCanvasFingerprint(),
            getNavigatorInfo(),
            getScreenInfo(),
            getPlugins(),
            getTimezoneOffset(),
            window.location.host
        ].join('cs1.6');

        return fingerprintData;
    }

    private hashComponents(data: string): string {
        let hash = 0;
        for (let i = 0; i < data.length; i++) {
            const char = data.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString();
    }

    public getFingerprint(): string {
        const data = this.collectBrowserData();
        
        return this.hashComponents(data);
    }

    verificarCodigoAutenticacao(codigo: string): Observable<any> {
        let headers = new HttpHeaders().set('noauth', 't');
        return this.http.get<boolean>(`${API}/api/usuario/validaracesso/${this.tempUser.Id}/${this.fingerprint}/${codigo}`, { headers: headers });
    }
}
